/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PlaceHolder from "../../../images/Placeholder2.svg";
import { PortableText } from "@portabletext/react";
import PortableTextComponent from "../../serializers/portableTextComponents";
import { Link } from "gatsby";

const ProductTeaser = ({ entity }) => {
  console.log(entity);
  const gatsbyTopicIconImageData = getGatsbyImageData(
    entity.mainImage,
    { width: 400, height: 250 },
    sanityConfig
  );
  return (
    <div
      sx={{
        borderRadius: "15px",
        position: "relative",
        background: "lightGray",
        "&:before": {
          content: "''",
          background: "#fff",
          position: "absolute",
          top: "4px",
          left: "4px",
          right: "4px",
          bottom: "4px",
          borderRadius: "15px",
        },
        ".shop-teaser": {
          fontSize: "0.875rem",
          borderRadius: "1.5rem",
          color: "darkGray",
          textDecoration: "none",
          cursor: "pointer",
          lineHeight: "1.5rem",
          display: "inline-block",
          padding: "0.7rem 3.5rem",
          lineHeight: "1.5rem",
          position: "relative",
          fontWeight: 600,
          background:
            "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
          "&:before": {
            content: "''",
            position: "absolute",
            top: "2px",
            left: "2px",
            right: "2px",
            bottom: "2px",
            borderRadius: "1.5rem",
            background: "white",
          },
          ":hover": {
            color: "white",
            "&:before": {
              display: "none",
            },
            background:
              "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
            boxShadow: "0px 15px 30px rgb(0 169 152 / 27%)",
          },
        },
        "&:hover": {
          background:
            "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
          ".shop-teaser": {
            fontSize: "0.875rem",
            borderRadius: "1.5rem",
            color: "white",
            textDecoration: "none",
            cursor: "pointer",
            lineHeight: "1.5rem",
            display: "inline-block",
            padding: "0.7rem 3.5rem",
            lineHeight: "1.5rem",
            fontWeight: 600,
            background:
              "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
            boxShadow: "0px 15px 30px rgb(0 169 152 / 27%)",
            ":hover": {
              bg: "primaryDark",
            },
            "&:before": {
              display: "none",
            },
          },
        },
      }}
    >
      <div
        sx={{
          padding: "20px",
          position: "relative",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          img: {
            borderRadius: "15px",
          },
        }}
      >
        <h3
          sx={{
            margin: "0px 0px 20px 0px",
            color: "darkGray",
            fontSize: "24px",
          }}
        >
          {entity.headline}
        </h3>
        {entity.mainImage ? (
          <Link
            sx={{
              color: "primary",
            }}
            to={entity.slug ? "/" + entity.slug.current : ""}
            className="learn-teaser"
          >
            <GatsbyImage image={gatsbyTopicIconImageData} alt={entity.headline} />
          </Link>
        ) : (
          <img src={PlaceHolder} />
        )}
        <div
          sx={{
            padding: "10px 10px 00px 10px",
            flexGrow: "1",
            fontSize: "19px",
            fontWeight: "bold",
          }}
        >
          <div>
            {entity.introText && (
              <PortableText
                value={entity.introText}
                components={PortableTextComponent}
              />
            )}
          </div>
        </div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "50px",
          }}
        >
          <Link
            sx={{
              color: "primary",
            }}
            to={entity.slug ? "/" + entity.slug.current : ""}
            className="learn-teaser"
          >
            Learn More
          </Link>
          <div
            sx={{
              margin: "10px 0px",
            }}
          >
            OR
          </div>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              href={entity.link ? entity.link.url : ""}
              target="_blank"
              className="shop-teaser"
            >
              <span
                sx={{
                  position: "relative",
                }}
              >
                Shop Now
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTeaser;
