/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { toPlainTextTruncate } from "../../../utils/helpers";

function truncate(text, length) {
  let textConcat = "";
  if (text.children) {
    text.children.forEach((element) => {
      if (element.text != undefined) {
        textConcat += element.text;
      }
    });
    return textConcat.length > length
      ? textConcat.substring(0, length) + "..."
      : textConcat;
  } else {
    return text.length > length ? text.substring(0, length) + "..." : text;
  }
}

const ArticleRelatedContent = ({ article }) => {
  return (
    <Link
      aria-label={article.headline && article.headline}
      to={
        article.post_type === "article"
          ? "/articles/" + article.slug.current
          : "/blog/" + article.slug.current
      }
      sx={{
        textDecoration: "none",
        ":hover": {
          opacity: ".7",
        },
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          flexWrap: "wrap",
          borderRadius: "8px",
        }}
      >
        <div
          sx={{
            width: ["100%", "100%", "256px"],
            display: ["none", "none", "block"],
            display: "flex",
            alignItems: "center",
          }}
        >
          {article.main_image && (
            <div className="article-teaser-main-image">
              <GatsbyImage
                sx={{
                  borderRadius: "8px",
                  height: "197px",
                }}
                image={article.main_image.asset.gatsbyImageData}
                alt={article.headline}
              />
            </div>
          )}
        </div>
        <div
          sx={{
            width: ["100%", "100%", "calc(100% - 256px)"],
            padding: [
              "20px 0px 0px 0px",
              "20px 0px 0px 0px",
              "0px 0px 0px 30px",
            ],
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {article.short_title ? (
            <div
              sx={{
                fontSize: "1.5rem",
                lineHeight: "1.625rem",
              }}
            >
              {toPlainTextTruncate(article.short_title, 50)}
            </div>
          ) : article.headline ? (
            <div
              sx={{
                fontSize: "1.5rem",
                lineHeight: "1.625rem",
              }}
            >
              {toPlainTextTruncate(article.headline, 50)}
            </div>
          ) : (
            ""
          )}
          {article._rawIntroText && (
            <div
              sx={{
                fontSize: "1rem",
                margin: "1rem 0rem",
                color: "textGray",
                lineHeight: "1.3rem",
              }}
            >
              {toPlainTextTruncate(article._rawIntroText[0], 80)}
            </div>
          )}
          <div
            sx={{
              textDecoration: "none",
              borderColor: "primary",
              display: "flex",
              alignItems: "center",
              marginTop: "7px",
            }}
          >
            <div
              sx={{
                textDecoration: "underline",
              }}
            >
              Read more
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default ArticleRelatedContent;
