import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function returnDescription(description) {
  let metaDescription = description;
  if (description && Array.isArray(description)) {
    metaDescription = "";
    description.forEach((element, index) => {
      if (element.children) {
        element.children.forEach((child) => {
          metaDescription += index != 0 ? " " : "" + child.text;
        });
      }
    });
  } else {
    metaDescription = description;
  }
  return metaDescription;
}

function returnKeywords(category) {
  let metaKeywords = ""

  if (category && Array.isArray(category)) {
    category.forEach((element, index) => {
      metaKeywords += index != 0 ? "," : "" + element.name;
    })
  }
  
  return metaKeywords;
}

function SEO({ description, lang, meta, title, category }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const defaultTitle = site.siteMetadata?.title;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: returnDescription(description),
        },
        {
          name: `keywords`,
          content: returnKeywords(category),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: returnDescription(description),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: returnDescription(description),
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
